import BaseBean from "@/utils/BaseBean";

export interface IXyzJdListDataObj {
    utilInst:XyzJdListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class XyzJdListUtil extends BaseBean{
    public dataObj:IXyzJdListDataObj

    constructor(proxy:any,dataObj:IXyzJdListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}