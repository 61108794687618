import XyzJdCard from './XyzJdCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import XyzJdListUtil,{IXyzJdListDataObj} from './xyzJdListUtil';
import language from './xyzJdLanguage'
import XyzCard from "@/views/project/order/finance/xyz/xyz/XyzCard.vue";
export default defineComponent ({
    name: 'XyzJdList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IXyzJdListDataObj=reactive<IXyzJdListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: XyzJdCard,
                cardFrom: 'XyzJdList',
                modelMethod: utils.OrderProviderApi.buildUrl('/xyzJd/pageData')
            },
            otherParams:{},
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new XyzJdListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed------------

        const formatPageInfo=(options:any)=>{
            return options;
        }
        //查看信用证卡片
        const openXyzCard=async (id:string)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:id,modelComp:XyzCard,ownerComp:proxy,cardFrom:'xyzJdList',
                title:'信用证详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
        }
        return{
            ...toRefs(dataObj),formatPageInfo,openXyzCard
        }
    }
});